import React from 'react';

import {
	SolutionHero,
	SolutionDiagram,
	SolutionFeatures,
	SolutionBusiness
} from 'components/Solution';
import { DarkCta, SEO } from 'components/UI';
import content from 'data/solution.json';
import analytics from 'data/analytics.json';
import seo from 'data/seo.json';

export default function Solutions() {
	return (
		<>
			<SEO
				title={seo.solutionTitle}
				description={seo.solutionDescription}
				ogTitle={seo.solutionOgTitle}
				ogDescription={seo.solutionOgDescription}
				ogImage={seo.solutionOgImage}
			/>
			<SolutionHero />
			<SolutionDiagram />
			<DarkCta
				name={analytics.solutionPortofolio}
				title={content.portofolio.title}
				description={content.portofolio.description}
				cta={content.portofolio.cta}
				to="/case-studies/"
				video
			/>
			<SolutionFeatures />
			<SolutionBusiness />
		</>
	);
}
